import * as React from "react";
import {
  Wrapper,
  AboutUsWrapper,
} from "./APropos.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";

const APropos = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>ABOUT US</h1>
      <div className={AboutUsWrapper}>
      <br />
      <p style={{fontSize:"25px"}}>GHOST GALERIE: Post-graffiti and its founding fathers</p>
        <br />
        <br />
        <p style={{fontSize:"16px"}}>Make known or recognized the artists from the origin of one of the last great artistic cultures of the 20th century is the ambition of Stéphane Miquel and Caroline Pozzo di Borgo, who have been living in Marseille since 2012. Firstly working in floor, GHOST GALERIE Marseille -located at 2, rue de Belloi in the 6th arrondissement of Marseille- opens its doors in 2018 with a retrospective of the artist Dondi White (the ultimate style master) in a private mansion in the city center on the ground floor, on a former police station ! Ironically, for these teenage vandals who created more than a movement : a culture.</p>
        <br />
        <p style={{fontSize:"16px"}}>As former great visionary dealers and gallery owners have done previously, GHOST GALERIE Marseille promotes one of the most fantastic artistic revolution of the last century : Post-graffiti, an art often misunderstood. The gallery pays tribute to the great masters of aerosol, the pioneers of old school New York graffiti and the representatives of the art scene of the East Village in New York. A unique and inspiring place that contributes every day to give this movement the visibility it deserves.</p>
        <br />
        <p style={{fontSize:"16px"}}>Dondi White, Rammellzee, Futura 2000, A-One but also DAZE, Lee Quinones, Lady Pink, Kool Koor, Crash, BLADE, Phase2, Zephyr, Seen… rub shoulders with artists from the East Village scene such as Kenny Scharf , Keith Haring, Richard Hambleton, Rick Prol, John Fekner or even Martin Wong… A trip to this New York which is so specific, creative and bubbling. An immersion in this city that was both muse and black widow at a time that dared to be different. A culture born in New York in the mid-1970s that is still present in the 21st century in fields as varied as music, dance, fine arts and fashion.</p>
        <br />
        <p style={{fontSize:"16px"}}>A second-market gallery, on the move, just like the foundations of graffiti. Retrospectives, group shows ... alongside iconic works, others bring their uniqueness and their share of imagination. A unique experience of immersion in these works produced by teenagers born in the Bronx or Brooklyn in the 1960s. Vandal writers who made the streets and subway trains their first workshops. Works by great artists (many have unfortunately disappeared) who knew how to bring the aerosol bomb as a symbol of their freedom. But also an encounter with a New York neighborhood, certainly the most exciting of the 80s.</p>
        <br />
        <p style={{fontSize:"16px"}}>In the mid-1980s, The New York Times declared : “ The East Village was the most interesting and, perhaps, the most exciting neighborhood in the most interesting and exciting city in the world. It has become a district recognized nationally and internationally for its counterculture ideas and trends, as a center of East Coast Punk rock culture and the New Wave movement. This included the emergence of art galleries and various music venues as legendary and world-famous destinations. It is in this cultural revival that the city's communities meet and that Hip-Hop, the art world and the Rock and Punk universe intersect. This exceptional environment will see a singular artistic scene where worlds with infinite contours mingle. A neighborhood where the most unlikely encounters have happened and where the East Village scene has introduced a pantheon of big names such as Jean-Michel Basquiat, Keith Haring, Dondi White, Futura 2000, Jenny Holzer, Peter Hujar, David Wojnarowich, Patti Smith, Blondie, Madonna…</p>
        <br />
      </div>
    </div>
  );
};

export default APropos;
